import React, { useState, useEffect } from "react";
import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { Clipboard, Eye, Pencil } from "lucide-react";

const Dashboard = () => {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [domainFilter, setDomainFilter] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedUser, setSelectedUser] = useState(null);
    const [weeklyUsage, setWeeklyUsage] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(20); // Default rows per page
    const [searchQuery, setSearchQuery] = useState("");
    const [editingUserId, setEditingUserId] = useState(null);
    const [editingName, setEditingName] = useState("");

    const handleEditClick = (user) => {
        setEditingUserId(user.id);
        setEditingName(user.name || ""); // Pre-fill with existing name or empty
    };

    const handleNameChange = (e) => {
        setEditingName(e.target.value);
    };

    const handleSaveName = async (userId) => {
        try {
            const userRef = doc(db, "users", userId);
            await updateDoc(userRef, { name: editingName });
            // Update the local state to reflect the change
            setUsers((prevUsers) =>
                prevUsers.map((user) =>
                    user.id === userId ? { ...user, name: editingName } : user
                )
            );
            setFilteredUsers((prevUsers) =>
                prevUsers.map((user) =>
                    user.id === userId ? { ...user, name: editingName } : user
                )
            );
            setEditingUserId(null); // Exit edit mode
        } catch (error) {
            console.error("Error updating name:", error);
        }
    };


    useEffect(() => {
        // Fetch all users from Firestore
        const fetchUsers = async () => {
            const usersCollection = collection(db, "users");
            const userDocs = await getDocs(usersCollection);
            const userData = userDocs.docs
                .map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }))
                // Exclude the specific user
                .filter((user) => user.email !== "khanq@naomedical.com")
                .filter((user) => user.email !== "imtherealdeal66@gmail.com")
                .filter((user) => user.email !== "iamqasimk@gmail.com")
                .filter((user) => user.email !== "iamqasimmk@gmail.com")
                .filter((user) => user.email !== "leomalikmalikleo@gmail.com");

            setUsers(userData);
            setFilteredUsers(userData);

            // Calculate weekly usage
            const weeklyData = calculateWeeklyUsage(userData);
            setWeeklyUsage(weeklyData);
        };
        fetchUsers();
    }, []);

    useEffect(() => {
        let filtered = users;

        if (domainFilter) {
            if (domainFilter === "others") {
                filtered = filtered.filter(
                    (user) =>
                        !user.email.includes("@gmail.com") &&
                        !user.email.includes("@naomedical.com")
                );
            } else {
                filtered = filtered.filter((user) =>
                    user.email.includes(domainFilter)
                );
            }
        }

        if (searchQuery) {
            filtered = filtered.filter((user) =>
                user.email.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        setFilteredUsers(filtered);
    }, [domainFilter, searchQuery, users]);

    // Filter users by email domain
    const handleDomainFilter = (domain) => {
        setDomainFilter(domain);
        if (domain === "others") {
            setFilteredUsers(
                users.filter(
                    (user) =>
                        !user.email.includes("@gmail.com") &&
                        !user.email.includes("@naomedical.com")
                )
            );
        } else if (domain) {
            setFilteredUsers(users.filter((user) => user.email.includes(domain)));
        } else {
            setFilteredUsers(users); // Reset to all users if no domain is selected
        }
    };


    // Get current rows for the table
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const sortedUsers = [...filteredUsers].sort((a, b) => {
        // Calculate valid session counts for both users
        const aValidSessions = a.sessions ? a.sessions.filter((session) => !isNaN(session.usage)).length : 0;
        const bValidSessions = b.sessions ? b.sessions.filter((session) => !isNaN(session.usage)).length : 0;

        // Primary sorting: valid session length
        if (bValidSessions !== aValidSessions) {
            return bValidSessions - aValidSessions;
        }

        // Secondary sorting: old usage (descending)
        const aUsage = a.usage || 0;
        const bUsage = b.usage || 0;
        return bUsage - aUsage;
    });

    const currentRows = sortedUsers.slice(indexOfFirstRow, indexOfLastRow);
    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Handle selecting a user
    const handleUserClick = (user) => {
        const validSessions = user.sessions
            ? user.sessions.filter((session) => !isNaN(session.usage))
            : [];
        const sortedSessions = validSessions.sort(
            (a, b) => new Date(b.date) - new Date(a.date)
        );
        setSelectedUser({ ...user, sessions: sortedSessions });
    };


    const calculateWeeklyUsage = (users) => {
        const weeklyStats = {};

        users.forEach((user) => {
            const userAddedToWeek = {};

            if (user.sessions) {
                user.sessions.forEach((session) => {
                    const sessionDate = new Date(session.date);

                    if (isNaN(sessionDate)) return; // Skip invalid dates

                    // Adjust to make Monday the first day of the week
                    const day = sessionDate.getDay(); // 0 (Sunday) to 6 (Saturday)
                    const diffToStartOfWeek = day === 0 ? -6 : 1 - day; // Sunday is treated as the last day
                    const startOfWeek = new Date(sessionDate);
                    startOfWeek.setDate(sessionDate.getDate() + diffToStartOfWeek);
                    startOfWeek.setHours(0, 0, 0, 0); // Reset time to start of day

                    const endOfWeek = new Date(startOfWeek);
                    endOfWeek.setDate(startOfWeek.getDate() + 6); // Add 6 days to get Sunday
                    endOfWeek.setHours(23, 59, 59, 999); // End of the day

                    // Format the week range
                    const weekRange = `${startOfWeek.toLocaleDateString("en-US", {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                    })} - ${endOfWeek.toLocaleDateString("en-US", {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                    })}`;

                    // Initialize weekly stats
                    if (!weeklyStats[weekRange]) {
                        weeklyStats[weekRange] = {
                            usage: 0,
                            gmailUsers: new Set(),
                            naomedicalUsers: new Set(),
                            otherUsers: new Set(),
                        };
                    }

                    // Add usage
                    weeklyStats[weekRange].usage += session.usage || 0;

                    // Ensure the user is only added once per week
                    if (!userAddedToWeek[weekRange]) {
                        if (user.email.includes("@gmail.com")) {
                            weeklyStats[weekRange].gmailUsers.add(user.email);
                        } else if (user.email.includes("@naomedical.com")) {
                            weeklyStats[weekRange].naomedicalUsers.add(user.email);
                        } else {
                            weeklyStats[weekRange].otherUsers.add(user.email);
                        }
                        userAddedToWeek[weekRange] = true; // Mark user as added for this week
                    }
                });
            }
        });

        // Convert to an array and prepare for rendering
        return Object.entries(weeklyStats)
            .map(([week, stats]) => ({
                week,
                usage: stats.usage / 60, // Convert seconds to minutes
                gmailUsers: stats.gmailUsers.size,
                naomedicalUsers: stats.naomedicalUsers.size,
                otherUsers: stats.otherUsers.size,
            }))
            .sort((a, b) => new Date(a.week.split(" - ")[0]) - new Date(b.week.split(" - ")[0])); // Sort by week start date
    };



    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            <h1 className="text-3xl font-bold mb-6">Harmoni Dashboard</h1>
            <TotalUsage users={filteredUsers} />
            {/* Domain Filter */}
            <div className="flex flex-row justify-center items-center gap-5 mb-5">
                <div>
                    <label className="block text-gray-700 font-bold mb-2">
                        Filter by Email Domain
                    </label>
                    <select
                        value={domainFilter}
                        onChange={(e) => handleDomainFilter(e.target.value)}
                        className="border border-gray-300 p-2 rounded-md w-full"
                    >
                        <option value="">All</option>
                        <option value="@gmail.com">@gmail.com</option>
                        <option value="@naomedical.com">@naomedical.com</option>
                        <option value="others">Others</option>
                    </select>
                </div>
                {/* Email Search */}
                <div>
                    <label className="block text-gray-700 font-bold mb-2">
                        Search by Email
                    </label>
                    <input
                        type="text"
                        placeholder="Enter email to search"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="border border-gray-300 p-2 rounded-md w-full"
                    />
                </div>
            </div>

            {/* User Table */}
            <div className="bg-white rounded-lg shadow overflow-hidden">
                <table className="table-auto w-full text-left">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="px-4 py-2">Name</th>
                            <th className="px-4 py-2">Email</th>
                            <th className="px-4 py-2">User Type</th>
                            <th className="px-4 py-2">Total Sessions</th>
                            <th className="px-4 py-2">Session Usage (Mins)</th>
                            <th className="px-4 py-2">Old Usage (Mins)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentRows.map((user) => {
                            // Calculate total session usage for this user
                            const totalSessionUsage = user.sessions
                                ? user.sessions.reduce((acc, session) => acc + (session.usage || 0), 0) / 60 // Convert seconds to minutes
                                : 0;


                            return (
                                <tr key={user.id}
                                    className="border-t hover:bg-gray-100"
                                >
                                    <td className="px-4 py-2 flex items-center gap-5">
                                        {editingUserId === user.id ? (
                                            <>
                                                <input
                                                    type="text"
                                                    value={editingName}
                                                    onChange={handleNameChange}
                                                    className="border border-gray-300 rounded p-1"
                                                />
                                                <button
                                                    onClick={() => handleSaveName(user.id)}
                                                    className="ml-2 bg-blue-500 text-white px-2 py-1 rounded"
                                                >
                                                    Save
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                <button
                                                    onClick={() => handleEditClick(user)}
                                                    className=" text-blue-500"
                                                >
                                                    <Pencil color="blue" size={18} />
                                                </button>
                                                {user.name || "N/A"}
                                            </>
                                        )}
                                    </td>
                                    <td className="">
                                        <button
                                            onClick={() => {
                                                navigator.clipboard.writeText(user.email);
                                            }}
                                        >
                                            <Clipboard color="green" />
                                        </button>
                                        {user.email}
                                    </td>
                                    <td className="px-4 py-2">{user.user_type}</td>
                                    <td className="px-4 py-2 flex gap-4 text-lg font-bold">
                                        {
                                            user.sessions
                                                ? user.sessions.filter((session) => !isNaN(session.usage)).length
                                                : 0
                                        }
                                        <button
                                            className="h-5 w-5"
                                            onClick={() =>
                                                handleUserClick({
                                                    ...user,
                                                    sessions: user.sessions
                                                        ? user.sessions.filter((session) => !isNaN(session.usage))
                                                        : [],
                                                })
                                            }
                                        >
                                            <Eye color="blue" />
                                        </button>
                                    </td>
                                    <td className="px-4 py-2">{totalSessionUsage ? (totalSessionUsage.toFixed(2)) : ""}</td>
                                    <td className="px-4 py-2">{user.usage ? ((user.usage / 60).toFixed(2)) : ""}</td>
                                </tr>
                            );
                        })}

                    </tbody>
                </table>
            </div>
            {/* Pagination */}
            <div className="flex justify-between items-center mt-4">
                <button
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                    className={`px-4 py-2 rounded-lg ${currentPage === 1 ? "bg-gray-300 cursor-not-allowed" : "bg-blue-500 text-white"
                        }`}
                >
                    Previous
                </button>
                <div className="flex items-center justify-center">
                    {Array.from({ length: Math.ceil(filteredUsers.length / rowsPerPage) }, (_, index) => (
                        <button
                            key={index + 1}
                            onClick={() => paginate(index + 1)}
                            className={`px-3 py-1 mx-1 rounded-lg ${currentPage === index + 1
                                ? "bg-blue-500 text-white"
                                : "bg-gray-200 hover:bg-blue-100"
                                }`}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
                <div className="flex items-center justify-center gap-4">
                    <label className="block text-gray-700 font-bold">
                        Rows per page:
                    </label>
                    <select
                        value={rowsPerPage}
                        onChange={(e) => setRowsPerPage(Number(e.target.value))}
                        className="border border-gray-300  rounded-md"
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                    </select>
                </div>
                <button
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === Math.ceil(filteredUsers.length / rowsPerPage)}
                    className={`px-4 py-2 rounded-lg ${currentPage === Math.ceil(filteredUsers.length / rowsPerPage)
                        ? "bg-gray-300 cursor-not-allowed"
                        : "bg-blue-500 text-white"
                        }`}
                >
                    Next
                </button>
            </div>
            {selectedUser && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white rounded-lg shadow-lg w-3/4 p-6 h-[90%] overflow-y-auto">
                        <div className="flex justify-between items-center">
                            <h2 className="text-xl font-bold">Sessions for {selectedUser.email}</h2>
                            <button
                                className="text-gray-500 hover:text-gray-800 text-2xl font-bold"
                                onClick={() => setSelectedUser(null)} // Close modal
                            >
                                ✕
                            </button>
                        </div>
                        <table className="table-auto w-full text-left">
                            <thead className="bg-gray-200">
                                <tr>
                                    <th className="px-4 py-2">Language</th>
                                    <th className="px-4 py-2">Usage (Minutes)</th>
                                    <th className="px-4 py-2">Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedUser.sessions.map((session, index) => (
                                    <tr key={index} className="border-t">
                                        <td className="px-4 py-2">{session.targetLang}</td>
                                        <td className="px-4 py-2">{(session.usage / 60).toFixed(2)} minutes</td>
                                        <td className="px-4 py-2">{new Date(session.date).toLocaleString()}</td>
                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    </div>
                </div>
            )}

            {/* Weekly Usage Table */}
            <div className="bg-white rounded-lg shadow p-4 mt-10">
                <h2 className="text-xl font-bold text-gray-700 mb-4">Weekly Usage</h2>
                <table className="table-auto w-full text-left">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="px-4 py-2">Week</th>
                            <th className="px-4 py-2">Usage (Minutes)</th>
                            {/* <th className="px-4 py-2">Gmail Users</th>
                            <th className="px-4 py-2">NaoMedical Users</th>
                            <th className="px-4 py-2">Other Users</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {weeklyUsage.map((weekData) => (
                            <tr key={weekData.week} className="border-t">
                                <td className="px-4 py-2">{weekData.week}</td>
                                <td className="px-4 py-2">{weekData.usage.toFixed(2)} minutes</td>
                                {/* <td className="px-4 py-2">{weekData.gmailUsers}</td>
                                <td className="px-4 py-2">{weekData.naomedicalUsers}</td>
                                <td className="px-4 py-2">{weekData.otherUsers}</td> */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

        </div>
    );
};

export default Dashboard;

const TotalUsage = ({ users }) => {

    const calculateTotalUsage = (users) => {
        return users.reduce((totalUsage, user) => {
            const userSessionUsage = user.sessions
                ? user.sessions.reduce((sessionTotal, session) => sessionTotal + (session.usage || 0), 0)
                : 0;
            return totalUsage + userSessionUsage;
        }, 0);
    };

    const totalUsage = users.reduce((acc, user) => acc + (user.usage || 0), 0);
    const totalUsageInMinutes = totalUsage / 60;
    const totalNewUsage = calculateTotalUsage(users);
    const totalNewUsageInMinutes = totalNewUsage / 60;
    const excludedEmails = [
        "khanq@naomedical.com",
        "imtherealdeal66@gmail.com",
        "iamqasimk@gmail.com",
        "iamqasimmk@gmail.com",
        "leomalikmalikleo@gmail.com",
    ];

    const totalSessions = users.reduce((total, user) => {
        // Skip users with excluded emails
        if (excludedEmails.includes(user.email)) return total;

        // Filter sessions with valid usage
        const validSessions = user.sessions
            ? user.sessions.filter((session) => !isNaN(session.usage)).length
            : 0;

        return total + validSessions;
    }, 0);

    return (
        <div className="flex gap-5">
            <div className="bg-white rounded-lg shadow p-4 mb-6">
                <h2 className="text-xl font-bold text-gray-700 mb-2">Total Usage OLD</h2>
                <p className="text-2xl font-bold text-blue-600">
                    {totalUsageInMinutes.toFixed(2)} minutes
                </p>
            </div>
            <div className="bg-white rounded-lg shadow p-4 mb-6">
                <h2 className="text-xl font-bold text-gray-700 mb-2">Total Usage NEW</h2>
                <p className="text-2xl font-bold text-blue-600">
                    {totalNewUsageInMinutes.toFixed(2)} minutes
                </p>
            </div>
            <div className="bg-white rounded-lg shadow p-4 mb-6">
                <h2 className="text-xl font-bold text-gray-700 mb-2">Total Users</h2>
                <p className="text-2xl font-bold text-blue-600">{users.length}</p>
            </div>
            <div className="bg-white rounded-lg shadow p-4 mb-6">
                <h2 className="text-xl font-bold text-gray-700 mb-2">Total Sessions</h2>
                <p className="text-2xl font-bold text-blue-600">{totalSessions}</p>
            </div>
        </div>
    );
};
