import { useState, useEffect, useCallback, useRef } from "react";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { useUser } from "../../context/AppContext";
import { db } from "../../firebase";

export const useTimer = () => {
    const { user, setUser, isAuth, sessionId } = useUser();
    const [remainingTime, setRemainingTime] = useState<number>(user?.time || 0);
    const [countUpTime, setCountUpTime] = useState<number>(0);
    const [isRunning, setIsRunning] = useState(false);

    const isRunningRef = useRef(false);
    const startTimeRef = useRef<number | null>(null);
    const countUpStartRef = useRef<number | null>(null);
    const heartbeatIntervalRef = useRef<NodeJS.Timeout | null>(null);

    const localTimeRef = useRef<number>(user?.time || 0);
    const localUsageRef = useRef<number>(
        user?.sessions?.find((session: any) => session.id === sessionId)?.usage || 0
    );

    const clearHeartbeat = useCallback(() => {
        if (heartbeatIntervalRef.current) {
            clearInterval(heartbeatIntervalRef.current);
            heartbeatIntervalRef.current = null;
        }
    }, []);

    const calculateUpdates = useCallback(() => {
        if (startTimeRef.current === null || countUpStartRef.current === null) {
            return { time: localTimeRef.current, usage: localUsageRef.current };
        }

        const now = Date.now();
        const elapsedTime = (now - startTimeRef.current) / 1000;
        const countUpElapsed = (now - countUpStartRef.current) / 1000;

        const updatedTime = Math.max(localTimeRef.current - elapsedTime, 0);
        const updatedUsage = localUsageRef.current + countUpElapsed;

        return { time: updatedTime, usage: updatedUsage };
    }, []);

    const sendHeartbeat = useCallback(async () => {
        if (!isRunningRef.current || startTimeRef.current === null || !isAuth?.uid) return;

        const { time: updatedTime, usage: updatedUsage } = calculateUpdates();

        try {
            const userDocRef = doc(db, "users", isAuth.uid);

            // Fetch the user's document
            const userDoc = await getDoc(userDocRef);
            if (!userDoc.exists()) {
                console.error("User document does not exist");
                return;
            }

            const userData = userDoc.data();
            const updatedSessions = (userData.sessions || []).map((session: any) =>
                session.id === sessionId
                    ? { ...session, usage: updatedUsage }
                    : session
            );

            // Update the session's usage in the user document
            await updateDoc(userDocRef, {
                time: updatedTime, // Adjust the user's overall remaining time
                sessions: updatedSessions, // Update the session's usage
            });
        } catch (error) {
            console.error("Heartbeat failed:", error);
        }
    }, [calculateUpdates, isAuth?.uid, sessionId]);

    const stopTimer = useCallback(async () => {
        if (!isRunningRef.current || startTimeRef.current === null || !isAuth?.uid) return;

        clearHeartbeat();

        const { time: updatedTime, usage: updatedUsage } = calculateUpdates();

        try {
            const userDocRef = doc(db, "users", isAuth.uid);

            // Fetch the user's document
            const userDoc = await getDoc(userDocRef);
            if (!userDoc.exists()) {
                console.error("User document does not exist");
                return;
            }

            const userData = userDoc.data();
            const updatedSessions = (userData.sessions || []).map((session: any) =>
                session.id === sessionId
                    ? { ...session, usage: updatedUsage }
                    : session
            );

            // Update the session's usage in the user document
            await updateDoc(userDocRef, {
                time: updatedTime,
                sessions: updatedSessions,
            });

            // console.log("Final update sent successfully");

            // Re-fetch user data for accuracy
            const updatedDoc = await getDoc(userDocRef);
            if (updatedDoc.exists()) {
                const freshUserData = updatedDoc.data();
                const updatedUser = { ...user, ...freshUserData };
                setUser(updatedUser);

                localTimeRef.current = freshUserData.time || 0;
                localUsageRef.current =
                    freshUserData.sessions.find((session: any) => session.id === sessionId)?.usage ||
                    0;

                setRemainingTime(updatedTime);
                setCountUpTime(updatedUsage - localUsageRef.current);
            }
        } catch (error) {
            console.error("Final update failed:", error);
        }

        setIsRunning(false);
        isRunningRef.current = false;
        startTimeRef.current = null;
        countUpStartRef.current = null;
    }, [calculateUpdates, clearHeartbeat, isAuth?.uid, setUser, sessionId, user]);

    const startTimer = useCallback(async () => {
        if (isRunningRef.current || !isAuth?.uid) return;

        // Always fetch fresh user data before starting
        const userDocRef = doc(db, "users", isAuth.uid);
        const freshDoc = await getDoc(userDocRef);
        if (freshDoc.exists()) {
            const freshData = freshDoc.data();

            const updatedUser = { ...user, ...freshData };
            setUser(updatedUser);

            localTimeRef.current = freshData.time || 0;
            localUsageRef.current =
                freshData.sessions.find((session: any) => session.id === sessionId)?.usage || 0;
        }

        clearHeartbeat();

        startTimeRef.current = Date.now();
        countUpStartRef.current = Date.now();
        isRunningRef.current = true;

        setIsRunning(true);
        setCountUpTime(0);

        heartbeatIntervalRef.current = setInterval(sendHeartbeat, 10000); // Heartbeat every 5 seconds
        // console.log("Heartbeat interval started");
    }, [clearHeartbeat, sendHeartbeat, isAuth?.uid, setUser, user, sessionId]);

    useEffect(() => {
        return () => {
            clearHeartbeat();
            // console.log("Cleanup on unmount");
        };
    }, [clearHeartbeat]);

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (isRunning && startTimeRef.current !== null) {
            timer = setInterval(() => {
                const { time: updatedTime, usage: updatedUsage } = calculateUpdates();
                setRemainingTime(Math.max(updatedTime, 0));
                setCountUpTime(updatedUsage - localUsageRef.current);
            }, 1000);
        }
        return () => {
            if (timer) clearInterval(timer);
        };
    }, [isRunning, calculateUpdates]);

    return { remainingTime, countUpTime, isRunning, startTimer, stopTimer };
};
