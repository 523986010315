import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import OnboardingPage from "./pages/onboarding/OnBoarding";
import { Home } from "./pages/home/HomePage";
import AddEnterpriseUsers from "./pages/enterprise/AddEnterpriseUsers";
import { useUser } from "./context/AppContext";
import ProtectedRoute from "./utils/ProtectedRoute";
import { ConsolePage } from "./pages/translator-console/ConsolePage";
import AppLoader from "./components/ldr/app-loader";
import Dashboard from "./pages/usage-metric";
import { useAddToHomescreenPrompt } from "./AddToHomeScreen";
import { useState, useEffect } from "react";

function App() {
  const { loading, isAuth, user } = useUser();
  const [isAppInstalled, setIsAppInstalled] = useState(false);
  const [deferredPrompt, promptToInstall] = useAddToHomescreenPrompt();
  const [showInstallModal, setShowInstallModal] = useState(false);
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isIOS && isSafari) {
      setShowBanner(true);
    }
  }, []);

  useEffect(() => {
    checkIfInstalled();
  }, []);

  // Check if our PWA is installed (Chrome/Android only)
  const checkIfInstalled = async () => {
    if ("getInstalledRelatedApps" in navigator) {
      try {
        const relatedApps = await navigator.getInstalledRelatedApps();
        const installed = relatedApps.some(
          (app) => app.url === "https://yourwebsite.com/manifest.json"
        );
        setIsAppInstalled(installed);
      } catch (err) {
        console.warn("getInstalledRelatedApps error:", err);
      }
    }
  };

  // Show the custom install modal on Android/desktop Chrome if there's a valid prompt
  useEffect(() => {
    const isAppleDevice = /iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent) && !window.MSStream;
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (!isAppInstalled && deferredPrompt && !isAppleDevice) {
      setShowInstallModal(true);
    }

    // Show a custom install banner for iOS/macOS Safari users
    if (isAppleDevice && isSafari) {
      setShowBanner(true);
    }
  }, [isAppInstalled, deferredPrompt]);


  const handleInstallClick = async () => {
    try {
      await promptToInstall();
      setShowInstallModal(false);
    } catch (err) {
      console.log("Prompt dismissed or error:", err);
    }
  };

  if (loading) {
    return <AppLoader />;
  }

  return (
    <>
      {/* iOS Install Banner */}
      {showBanner && (
        <div className="fixed bottom-6 left-4 right-4 bg-white p-4 rounded-xl shadow-lg border border-gray-300 z-50 flex flex-col items-center text-center space-y-3">
          <p className="text-[15px] text-black leading-snug">
            Add <span className="font-semibold text-[#0D5C67]">Harmoni</span> to your Home Screen:
          </p>
          <p className="text-[14px] text-gray-700 flex items-center">
            Tap
            <img src="/share.svg" alt="Share icon" className="h-6 w-6 mx-1" />
            then select <span className="font-semibold">"Add to Home Screen"</span>.
          </p>
          <button
            className="px-4 py-2 bg-[#0D5C67] text-white text-sm font-medium rounded-lg shadow-md hover:bg-[#0D5C67] transition active:scale-95"
            onClick={() => setShowBanner(false)}
          >
            Got it!
          </button>
        </div>
      )}
      {showInstallModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6 shadow-md w-80 max-w-full">
            <h2 className="text-lg font-bold mb-2">Install Harmoni App</h2>
            <p className="text-sm text-gray-700 mb-4">
              Install our app on your device for a better experience.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                className="px-4 py-2 rounded bg-gray-300 text-gray-800 hover:bg-gray-400"
                onClick={() => setShowInstallModal(false)}
              >
                Maybe Later
              </button>
              <button
                className="px-4 py-2 rounded bg-[#0D5C67] text-white hover:bg-blue-700"
                onClick={handleInstallClick}
              >
                Install
              </button>
            </div>
          </div>
        </div>
      )}
      <Router>
        <div data-component="App">
          <Routes>
            <Route
              path="/"
              element={
                isAuth ? <Navigate to="/app" replace /> : <OnboardingPage />
              }
            />
            <Route
              path="/app"
              element={isAuth ? <Home /> : <Navigate to="/" replace />}
            />
            <Route
              path="/console"
              element={isAuth ? <ConsolePage /> : <Navigate to="/" replace />}
            />
            <Route
              path="/dash"
              element={
                isAuth
                  ? user?.email === "khanq@naomedical.com" || user?.email === "ari@naomedical.com" || user?.email === "haseeb@naomedical.com" || user?.email === "savkard@naomedical.com"
                    ? <Dashboard />
                    : <Navigate to="/app" replace />
                  : <Navigate to="/" replace />
              }
            />
            <Route
              path="/enterprise-dash"
              element={
                <ProtectedRoute requiredType="ENTERPRISE">
                  <AddEnterpriseUsers />
                </ProtectedRoute>
              }
            />
            <Route
              path="*"
              element={<div>404 - Page Not Found</div>}
            />
          </Routes>
        </div>
      </Router>
    </>
  );
}

export default App;
