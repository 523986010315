const LOCAL_RELAY_SERVER_URL: string =
    `wss://harmoni-relay-485783353493.us-central1.run.app` || '';
// const LOCAL_RELAY_SERVER_URL: string =
//     `ws://localhost:8080` || '';

import { useEffect, useRef, useCallback, useState, useMemo } from 'react';
import { RealtimeClient } from '@openai/realtime-api-beta';
import { ItemType } from '@openai/realtime-api-beta/dist/lib/client.js';
import { WavRecorder, WavStreamPlayer } from '../../lib/wavtools/index.js';
import { ChevronDown, Volume2, VolumeX, X, } from 'react-feather';
import { useLocation } from 'react-router-dom';
import Header from '../../components/header';
import languages from "../../data/languages.json"
import { useUser } from '../../context/AppContext';
import { useTimer } from '../../components/count-down/count';
import { MicButton } from '../../components/mic-button';
import translateText from '../../lib/spoken-transcriptions/index.js';

interface WavStreamPlayerWithGain extends WavStreamPlayer {
    gainNode?: GainNode;
}
interface RealtimeEvent {
    time: string;
    source: 'client' | 'server';
    count?: number;
    event: { [key: string]: any };
}

export function ConsolePage() {
    const wavRecorderRef = useRef<WavRecorder>(
        new WavRecorder({ sampleRate: 24000 })
    );
    const wavStreamPlayerRef = useRef<WavStreamPlayerWithGain>(
        new WavStreamPlayer({ sampleRate: 24000 })
    );
    const { user } = useUser()
    const location = useLocation();
    let recordingProcess: Promise<boolean> | null = null;
    const clientRef = useRef<any>(new RealtimeClient({ url: LOCAL_RELAY_SERVER_URL }));
    const startTimeRef = useRef<string>(new Date().toISOString());
    const [items, setItems] = useState<ItemType[]>([]);
    const [isConnected, setIsConnected] = useState(false);
    const [realtimeEvents, setRealtimeEvents] = useState<RealtimeEvent[]>([]);
    const [memoryKv, setMemoryKv] = useState<{ [key: string]: any }>({});
    const [translations, setTranslations] = useState<string[]>([]);
    const { isRunning, remainingTime, countUpTime, startTimer, stopTimer } = useTimer();
    const queryParams = new URLSearchParams(location.search);
    const targetLang = queryParams.get("targ-lang");
    const sourceLang = queryParams.get("src-lang");

    const instructions = `
    You are Harmoni, a strict translation assistant. Follow these rules exactly and without exception:
    
    1. **Introduce yourself in \${targetLang}:**
       - Write this sentence fully in \${targetLang}. For example, if \${targetLang} = 'Spanish':
         "Hola, soy Harmoni, tu intérprete de idiomas. Habla con claridad, sin pausas, y en tu idioma."
       - If \${targetLang} = 'Urdu':
         "سلام، میں ہارمونی ہوں، آپ کی زبان کی ترجمان۔ براہ مہربانی صاف اور بغیر وقفوں کے اپنی زبان میں بات کریں۔"
    
    2. **Translation Only:**
       - Your sole purpose is to **translate** casually what the user has said into the other language, without adding or omitting any details so that the translation is understandable by anyone.
       - Do not interpret, provide answers, or respond conversationally. Translate **only** the content of the user’s utterance. Do not hallucinate or make up content.
    
    3. **Maintain a Clear and Friendly Tone:**
       - Ensure that translations are phrased in a tone that is female, respectful, friendly, and professional.
       - Use simple, easy-to-understand language that can be comprehended by people with varying levels of literacy or familiarity with medical terminology.
    
    4. **Translation Direction:**
       - If the user speaks in English, translate it into **\${targetLang}**.
       - If the user speaks in **\${targetLang}**, translate it into English.
       - Always retain the same intent, tone, and meaning in your translation, and keep the translation simple and easily understandable.
    
    5. **Unintelligible Speech:**
       - If the user’s speech is unclear (e.g., mumbling, coughing), say "Can you please repeat that?".
    
    6. **No Interpretation or Assumptions:**
       - Never interpret or assume the user’s intent. Only translate the words spoken if you are 99% sure.
       - Do not answer questions, ask follow-up questions, or engage in conversation.
       - Do not hallucinate or make up content.
    
    7. **Strict Adherence:**
       - These rules are absolute. Never deviate or add any commentary, interpretation, or additional responses. Only translate.
    
    8. **Additional Clarifications and Fixes:**
       - Under no circumstance introduce yourself as “Waitress” or any role other than “Harmoni.”
       - Always translate into the **opposite** language from what the user speaks. Do not fail to switch languages.
       - If you are ever uncertain of the user’s words or meaning, politely say “Can you please repeat that?” rather than guess or invent details.
       - Do not hallucinate or provide any information beyond the user’s own utterance.
    `;



    const connectConversation = useCallback(async () => {
        if (!isRunning) {
            startTimer()
        }
        const client = clientRef.current;
        const wavRecorder = wavRecorderRef.current;
        const wavStreamPlayer = wavStreamPlayerRef.current;

        startTimeRef.current = new Date().toISOString();
        setIsConnected(true);
        setRealtimeEvents([]);
        setItems(client.conversation.getItems());

        await wavRecorder.begin();
        await wavStreamPlayer.connect();
        await client.connect();
        console.log(client)
        // Shimmer
        client.updateSession({
            modalities: ["text", "audio"],
            voice: "shimmer", // Set your preferred voice
            model: "gpt-4o-realtime-preview-2024-12-17",
            // model: "gpt-4o-mini-realtime-preview-2024-12-17",
            instructions: instructions,
            input_audio_format: "pcm16",
            output_audio_format: "pcm16",
            // temperature: 0.9,
            max_response_output_tokens: "inf",
            // input_audio_transcription: {
            //     model: "whisper-1",
            // },
            turn_detection: {
                type: 'server_vad',
                threshold: 0.6,
                prefix_padding_ms: 300,
                silence_duration_ms: 500,
                // create_response: false
            }
        });

        //coment for testing
        client.sendUserMessageContent([
            {
                type: `input_text`,
                text: `Hi, Introduce yourself in ${targetLang}!`,
            },
        ]);

        recordingProcess = wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }, []);

    const disconnectConversation = useCallback(async () => {
        await stopTimer()
        setIsConnected(false);
        setRealtimeEvents([]);
        // setItems([]);
        setMemoryKv({});
        setTranslations([])

        // Ensure all transcripts are fetched before saving
        const client = clientRef.current;
        // const conversationItems = client.conversation.getItems();

        client.disconnect();

        const wavRecorder = wavRecorderRef.current;
        await wavRecorder.end();

        const wavStreamPlayer = wavStreamPlayerRef.current;
        await wavStreamPlayer.interrupt();
    }, []);

    useEffect(() => {
        if (remainingTime <= 0 && isConnected && user.user_type === 'FREE') {
            disconnectConversation();
        }
    }, [remainingTime, isConnected, disconnectConversation]);

    const deleteConversationItem = useCallback(async (id: string) => {
        const client = clientRef.current;
        client.deleteItem(id);
    }, []);

    useEffect(() => {
        const conversationEls = [].slice.call(
            document.body.querySelectorAll('[data-conversation-content]')
        );
        for (const el of conversationEls) {
            const conversationEl = el as HTMLDivElement;
            conversationEl.scrollTop = conversationEl.scrollHeight;
        }
    }, [items]);


    useEffect(() => {
        // Get refs
        const wavStreamPlayer = wavStreamPlayerRef.current;
        const client = clientRef.current;
        client.updateSession({ instructions: instructions });
        client.updateSession({ input_audio_transcription: { model: 'whisper-1' } });

        client.on('realtime.event', (realtimeEvent: RealtimeEvent) => {
            setRealtimeEvents((realtimeEvents) => {
                const lastEvent = realtimeEvents[realtimeEvents.length - 1];
                if (lastEvent?.event.type === realtimeEvent.event.type) {
                    lastEvent.count = (lastEvent.count || 0) + 1;
                    return realtimeEvents.slice(0, -1).concat(lastEvent);
                } else {
                    return realtimeEvents.concat(realtimeEvent);
                }
            });
        });
        client.on('error', (event: any) => console.error(event));
        client.on('conversation.interrupted', async () => {
            const trackSampleOffset = await wavStreamPlayer.interrupt();
            if (trackSampleOffset?.trackId) {
                const { trackId, offset } = trackSampleOffset;
                await client.cancelResponse(trackId, offset);
            }
        });
        client.on('conversation.updated', async ({ item, delta }: any) => {
            const items = client.conversation.getItems();
            if (delta?.audio) {
                wavStreamPlayer.add16BitPCM(delta.audio, item.id);
            }
            if (item.status === 'completed' && item.formatted.audio?.length) {
                const wavFile = await WavRecorder.decode(
                    item.formatted.audio,
                    24000,
                    24000
                );
                item.formatted.file = wavFile;
            }
            setItems(items);
        });
        setItems(client.conversation.getItems());
        return () => {
            client.reset();
        };
    }, []);


    const [selectedLang, setSelectedLang] = useState("both");
    const [isOpenSelect, setIsOpenSelect] = useState(false);

    const uniqueLanguages = useMemo(() => {
        return Array.from(new Set([sourceLang, targetLang].filter(Boolean)));
    }, [sourceLang, targetLang]);

    const handleToggleSelect = () => {
        setIsOpenSelect((prev) => !prev);
    };

    const getLanguageDetails = (langCode: string) => {
        return languages.find((lang) => lang.name === langCode);
    };

    const getSourceFlagByCode = (code: string | null) => {
        return languages.find((lang) => lang.name === code)?.flag || "/flags/english.svg";
    };

    const handleFilterBasedOnLang = (langCode: string) => {
        if (langCode === selectedLang && langCode !== "both") {
            setSelectedLang(""); // If the same language is selected, clear the filter
        } else {
            setSelectedLang(langCode); // Otherwise, apply the new language
        }
        setIsOpenSelect(false); // Close the dropdown after selecting
    };
    const filteredItems: any = items.filter((conversationItem) => {
        if (selectedLang === "both") return true;  // Show all for both languages
        const conversationLang = conversationItem.role === "user" ? sourceLang : targetLang;
        return conversationLang === selectedLang;
    });

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`; // Format as MM:SS
    }

    const [isMuted, setIsMuted] = useState(false);
    // Mute the microphone (stop sending audio data)
    const muteMic = () => {
        const wavRecorder = wavRecorderRef.current;
        if (wavRecorder && wavRecorder.stream) {
            wavRecorder.stream.getAudioTracks().forEach(track => {
                track.enabled = false;
            });
        }
    };

    // Unmute the microphone (resume sending audio data)
    const unmuteMic = () => {
        const wavRecorder = wavRecorderRef.current;
        if (wavRecorder && wavRecorder.stream) {
            wavRecorder.stream.getAudioTracks().forEach(track => {
                track.enabled = true;
            });
        }
    };

    // Example toggle function
    const toggleMute = () => {
        if (isMuted) {
            unmuteMic();
        } else {
            muteMic();
        }
        setIsMuted(!isMuted);
    };

    useEffect(() => {
        const translateSequentially = async () => {
            for (let i = 0; i < filteredItems.length; i++) {
                const conversationItem = filteredItems[i];

                // Skip if the translation already exists
                if (!translations[i] && conversationItem.formatted?.transcript) {
                    try {
                        const translatedText = await translateText(
                            conversationItem.formatted.transcript,
                            targetLang
                        );

                        // Update the translations array at index i
                        setTranslations((prev) => {
                            const updatedTranslations = [...prev];
                            updatedTranslations[i] = translatedText;
                            return updatedTranslations;
                        });
                    } catch (error) {
                        console.warn("Translation failed:", error);
                    }
                }
            }
        };

        translateSequentially();
    }, [filteredItems, targetLang]);

    useEffect(() => {
        let inactivityTimer: any;
        clearTimeout(inactivityTimer);

        inactivityTimer = setTimeout(() => {
            if (isConnected) {
                stopTimer();
                disconnectConversation();
                console.log("Session ended due to inactivity (no new items in the array for 20 seconds).");
            }
        }, 800000);

        return () => {
            clearTimeout(inactivityTimer);
        };
    }, [items]);

    return (
        <div className="h-screen w-screen overflow-hidden bg-gradient-to-b from-white to-sky-50 flex flex-col relative">
            <div className="flex-shrink-0 z-10">
                <Header showBackButton />
            </div>

            {user.user_type === 'FREE' && (
                <div className="mb-4 p-5 flex flex-row justify-start items-center gap-1 absolute top-[70px]">
                    <h1 className="text-lg font-semibold text-gray-800">
                        Demo Time:
                    </h1>
                    <div className="text-3xl font-bold text-teal-600">
                        {formatTime(Math.trunc(remainingTime))}
                    </div>
                </div>
            )}
            {/* <div className="mb-4 p-5 flex flex-row justify-start items-center gap-1 absolute top-[70px]">
                <h1 className="text-lg font-semibold text-gray-800">
                    Test Time:
                </h1>
                <div className="text-3xl font-bold text-teal-600">
                    {formatTime(Math.trunc(countUpTime))}
                </div>
            </div> */}
            <div className="h-[14rem] relative flex flex-col items-center justify-center top-6">
                <button
                    onClick={toggleMute}
                    className={`flex mb-4 items-center gap-2 px-4 py-2 transition duration-300 rounded-lg shadow-lg
                    ${isMuted ? "bg-red-500 text-white" : "bg-teal-500 text-white"}
                    hover:scale-105`}
                >
                    {isMuted ? (
                        <VolumeX className="w-5 h-5" />
                    ) : (
                        <Volume2 className="w-5 h-5" />
                    )}
                    <span className="font-semibold">
                        {isMuted ? "Unmute" : "Mute"}
                    </span>
                </button>

                <div className='relative flex flex-col justify-center items-center mt-2 mb-4'>
                    <MicButton
                        disabled={remainingTime <= 0 && user.user_type === 'FREE'}
                        isConnected={isConnected}
                        onStart={connectConversation}
                        onStop={disconnectConversation}
                        buttonStyle={isConnected ? 'alert' : 'regular'}
                        showRipple={isConnected}
                    />
                    {isConnected ? (
                        <p className='my-2 text-[#79AEB5]'>Press mic to stop</p>
                    ) : (
                        <p className='my-2 text-[#79AEB5]'>Press mic to start</p>
                    )}
                </div>
            </div>
            <div className="relative flex-grow overflow-x-hidden bg-white flex flex-col items-center justify-start transition-opacity">
                <div className="mb-[40px] w-full max-w-[100%]">
                    <div className="flex flex-col">
                        {/* Header - Stays at the top */}
                        <div className="sticky top-0 z-10 bg-white shadow-md">
                            <div className="flex items-center justify-start md:justify-between my-[14px] px-4">
                                <p className="w-[142px] h-[30px] text-[20px] font-medium leading-[30px] text-[#000] text-start">
                                    Transcription
                                </p>
                                {/* Language Select Dropdown */}
                                <div className="relative language-select">
                                    <button
                                        onClick={handleToggleSelect}
                                        className="flex items-center gap-1 cursor-pointer w-[160px] ml-4"
                                    >
                                        {getLanguageDetails(selectedLang)?.name && (
                                            <img
                                                src={getSourceFlagByCode(selectedLang)}
                                                alt={`${selectedLang} Flag`}
                                                width={14}
                                                height={14}
                                            />
                                        )}
                                        <span className="text-[13px] font-medium text-black">
                                            {getLanguageDetails(selectedLang)?.name || "Both Languages"}
                                        </span>
                                        <ChevronDown color="black" size={12} />
                                    </button>

                                    {isOpenSelect && (
                                        <div className="absolute top-full mt-1 bg-white border border-gray-200 border-t-0 shadow-lg rounded-br-[13.5px] rounded-bl-[13.5px] w-[175px] z-10">
                                            <ul className="py-1">
                                                <li
                                                    className="flex items-center gap-1 px-4 py-2 text-sm text-gray-800 cursor-pointer hover:bg-gray-100 text-[13px]"
                                                    onClick={() => handleFilterBasedOnLang("both")}
                                                >
                                                    Both Languages
                                                </li>
                                                {uniqueLanguages.map((langCode) => {
                                                    const language = getLanguageDetails(langCode || "");
                                                    return language ? (
                                                        <li
                                                            key={langCode}
                                                            className="flex items-center gap-1 px-4 py-2 text-sm text-gray-800 cursor-pointer hover:bg-gray-100 text-[13px]"
                                                            onClick={() => handleFilterBasedOnLang(langCode || "")}
                                                        >
                                                            <img
                                                                src={language.flag}
                                                                alt={`${language.name} Flag`}
                                                                width={14}
                                                                height={14}
                                                            />
                                                            {language.name}
                                                        </li>
                                                    ) : null;
                                                })}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Scrollable Conversation Content */}
                        <div
                            className="flex-grow bg-gray-50 px-4 overflow-y-auto" // Add scroll styles
                        >
                            {filteredItems.length > 0 ? (
                                filteredItems.map((conversationItem: any, i: number) => {

                                    return (

                                        <div
                                            key={conversationItem.id}
                                            className="w-full bg-white rounded-lg p-4 my-2 shadow-sm border border-gray-200"
                                        >
                                            {/* Display speaker role */}
                                            <div className="flex justify-between items-center mb-2">
                                                <p
                                                    className={`text-sm font-medium ${conversationItem.role === "user" ? "text-black" : "text-teal-600"
                                                        }`}
                                                >
                                                    {conversationItem.role === "user" ? `User` : `Harmoni`}
                                                </p>
                                                <button
                                                    className="text-red-500 hover:text-red-700"
                                                    onClick={() => deleteConversationItem(conversationItem.id)}
                                                >
                                                    <X size={16} />
                                                </button>
                                            </div>

                                            {/* Display message or transcript */}
                                            <div className="text-sm text-gray-800">
                                                {conversationItem.role === "user" ? (
                                                    <>
                                                        {translations[i + 1] || "Translating..."}
                                                        {/* {storedItems.find((storedItem: any) => storedItem.id === conversationItem.id)?.user} */}
                                                        {/* Optionally handle user role-specific content here */}
                                                    </>
                                                ) : (
                                                    <div>
                                                        {conversationItem.formatted.transcript
                                                            // storedItems.find((storedItem: any) => storedItem.id === conversationItem.id)?.text
                                                        }
                                                    </div>
                                                )}
                                            </div>

                                            {/* Display audio playback if available */}
                                            {conversationItem.formatted.file && (
                                                <audio
                                                    src={conversationItem.formatted.file.url}
                                                    controls
                                                    className="mt-2 w-full rounded"
                                                />
                                            )}
                                        </div>
                                    )
                                })
                            ) : (
                                <div className="text-center text-gray-500 mt-8">No conversation to display.</div>
                            )}
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
}
